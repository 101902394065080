import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/security/response.md"

export default function SecurityResponsePage() {
  return (
    <LegalLayout
      description="Have you discovered a web security flaw that might impact one of our products? Here's how you can report it."
      source={source}
      title="Security Response"
    />
  )
}
